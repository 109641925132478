// keep for future open positions

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import CareersDetailsView from '../../components/CareersDetails';

const CareersDetails = ({ location, data }) => (
  <CareersDetailsView
    location={location}
    title="PHP Senior Engineer - Software Careers | MCRO"
    description="PHP Developer at MCRO. Be part of the best front-end team in Cluj Napoca, Romania "
    metaContent="php, symfony framework, drupal, elastic search, aws"
    id="php"
    jobTitle="PHP Engineer"
    jobDescription={`If you are a forward thinking, problem solver with a strong passion for PHP at
  MCRO you will collaborate with an extremely talented and professional team of engineers that will boost
  your career towards success and will help you grow and deliver the best quality for top clients and
  innovative products.`}
    mailHref="mailto:jobs@mcro.tech?subject=PHP Job Application"
    roles={[
      'Develop new features or maintain an existing platform',
      'Sync up on a daily basis with a distributed team and respect the coding standards required',
      'Deliver in a controlled agile environment'
    ]}
    offerings={[
      'Competitive compensation',
      'Possibility to work remote when desired',
      'Work with the best professionals in the area',
      'Great work environment in our Cluj-Napoca Office',
      'Private healthcare insurance for employees'
    ]}
    requirements={[
      'Great communication skills verbal and written English',
      'Strong Experience with PHP and Symfony3 Framework',
      'Strong experience and understanding of SQL and noSQL Databases MySQL, MongoDb',
      'Strong experience and understanding of building a RESTfull API architecture',
      'Previous experience in building Restful APIs',
      'Previous experience with Amazon Web Services',
      'Previous experience with Elastic Search',
      'Previous experience with Kafka or other message queue system is a plus',
      'Previous experience with Drupal8 is a plus',
      'Previous experience with building Drupal8 modules and custom themes',
      'Experience building Drupal8 multi-site platform is a big plus *',
      'Experience with NodeJS is a plus *',
      'Experience in product management and analysis is a plus'
    ]}
    imageElement={data}
  />
);

CareersDetails.propTypes = {
  location: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({})
};

CareersDetails.defaultProps = {
  data: null
};

export default CareersDetails;

export const query = graphql`
  query phpCareerPage {
    file(relativePath: { eq: "careers-details-banner.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440)  {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
